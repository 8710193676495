import React, { Component } from 'react';

import JSONEditorReact from './JSONEditorReact';
import './App.css';

const schema = {
  title: 'Schema',
  type: 'object',
  properties: {},
  required: ['array', 'string', 'boolean']
};

const json = {
  'array': [1, 2, 3],
  'boolean': true,
  'null': null,
  'number': 123,
  'object': {'a': 'b', 'c': 'd'},
  'string': 'Hello World'
};

const modes = ['tree', 'form', 'view', 'code', 'text'];
const modes2 = ['tree', 'form', 'view', 'code', 'text'];

class App extends Component {
  state = {
    schema,
    text: JSON.stringify(json, null, 2),
    text2: JSON.stringify(json, null, 2),
    mode: 'code',
    mode2: 'code'
  };

  render() {
    return (
      <div className="app">
        <div className="contents">
          <JSONEditorReact
              schema={this.state.schema}
              text={this.state.text}
              mode={this.state.mode}
              modes={modes}
              indentation={2}
              onChangeText={this.onChangeText}
              onModeChange={this.onModeChange}
          />
        </div>

        <div className="contentsRight">
          <JSONEditorReact
              schema={this.state.schema}
              text={this.state.text2}
              mode={this.state.mode2}
              modes={modes2}
              indentation={2}
              onChangeText={this.onChangeText2}
              onModeChange={this.onModeChange2}
          />
        </div>
      </div>
    );
  }

  onChangeText = (text) => {
    console.info("text:"+text)
    this.setState({ text });
  };


  onModeChange = (mode) => {
    console.info("mode:"+mode)
    this.setState({ mode });
  };

  onChangeText2 = (text2) => {
    console.info("text2:"+text2)
    this.setState({ text2 });
  };


  onModeChange2 = (mode2) => {
    console.info("mode2:"+mode2)
    this.setState({ mode2 });
  };
}

export default App;
